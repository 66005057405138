import axios from "axios"

const baseURL = process.env.REACT_APP_BASEURL;

export const AuthLogin = async (Data) => {
    const response = await axios.post(`${baseURL}/auth/login`, Data)
    return response.data
}

export const postOrder = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/orders`, Data)
    return response
}

export const UploadOrderDocs = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/orders/uploads`, Data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return response
}

export const postCOA = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/coa`, Data);
    return response
}

export const postInvoice = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/invoice`, Data);
    return response
}

export const postCustomerAccount = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/customer_account`, Data);
    return response
}

export const postAccountAdmin = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/account_admin`, Data);
    return response
}

export const PostNews = async (Data) => {
    const response = await axios.post(`${baseURL}/dashboard/post/news`, Data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return response
}