import React from 'react';
import Chart from 'react-google-charts';

const ChartSalesYTD = ({ dataSource }) => {
    console.log(dataSource);
    
    const data = dataSource && dataSource.length > 0
        ? [
            ["Year", "TotalSales", "SalesYTD"],
            ...dataSource.map((item) => [
                parseInt(item.Year, 10),
                Number(item.TotalSales),
                Number(item.SalesYTD)
            ])
        ]
        : [
            // Placeholder data jika dataSource kosong atau null
            ["Year", "TotalSales", "SalesYTD"],
            ["No Data", 0, 0],
        ];

        const options = {
            title: "Sales YTD",
            curveType: "function",
            legend: { position: "top" },
            // vAxis: {
            //     format: 'short', // Menghindari tampilan desimal pada sumbu vertikal
            //     title: 'Sales Value',
            // },
            hAxis: {
                format: '####', // Menampilkan tahun tanpa desimal
                title: 'Year',
            },
        };

    return (
        <div style={{ width: '100%', textAlign: 'center' }}> {/* Center title and chart */}
            <h2>Sales Growth Chart</h2> {/* Display chart title */}
            <div style={{ width: '100%', height: '400px' }}>
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                    legendToggle
                />
            </div>
        </div>
    );
}

export default ChartSalesYTD;
