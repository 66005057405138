import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Pages/Login'
import Dashboard from './Pages/Dashboard'
import Order from './Pages/MenuOrder/Order'
import DetailOrder from './Pages/MenuOrder/Order/DetailOrder'
import SubCOA from './Pages/MenuOrder/COA'
import Sample from './Pages/MenuOrder/Sample'
import DetailSample from './Pages/MenuOrder/Sample/DetailSample'
import Invoice from './Pages/Invoice'
import DetailInvoice from './Pages/Invoice/DetailInvoice'
import Analytic from './Pages/Analytic'
import DashboardCustomer from './Pages/Customer/DashboardCustomer'
import DetailCarousel from './Pages/Dashboard/Carousel/DetailCarousel'
import FormOrder from './Pages/MenuOrder/Order/Form'
import Layouts from './Components/Layouts'
import DetailFormSample from './Pages/MenuOrder/Order/Form/DetailFormSample'
import FormCOA from './Pages/MenuOrder/COA/FormCOA'
import Profile from './Pages/Profil'
import FormInvoice from './Pages/Invoice/Form'
import Account from './Pages/Account'
import FormAccount from './Pages/Account/form'
import AccountAdmin from './Pages/AccountAdmin'
import News from './Pages/News'
import FormAccountAdmin from './Pages/AccountAdmin/form'
import FormNews from './Pages/News/form'
import Faktur from './Pages/Faktur'

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/faktur/:accountid/:code" element={<Faktur />} />
        <Route path="/dashboard/*" element={
          <Layouts>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/detailbanner/:id" element={<DetailCarousel />} />
              <Route path='analytic' element={<Analytic />} />
              <Route path='profile' element={<Profile />} />
              <Route path='account/*' element={
                <Routes>
                  <Route path='/' element={<Account />} />
                  <Route path='form' element={<FormAccount />} />
                </Routes>
              } />
              <Route path='account_admin/*' element={
                <Routes>
                  <Route path='/' element={<AccountAdmin />} />
                  <Route path='form' element={<FormAccountAdmin />} />
                </Routes>
              } />
              <Route path='news/*' element={
                <Routes>
                  <Route path='/' element={<News />} />
                  <Route path='form' element={<FormNews />} />
                </Routes>
              } />
              <Route path="/menuorder/*" element={
                <Routes>
                  <Route path='order' element={<Order />} />
                  <Route path='order/:accountid/:code' element={<DetailOrder />} />
                  <Route path='order/form' element={<FormOrder />} />
                  <Route path='order/sample/form' element={<DetailFormSample />} />
                  <Route path='sample' element={<Sample />} />
                  <Route path='sample/:accountid/:code' element={<DetailSample />} />
                  <Route path='coa' element={<SubCOA />} />
                  <Route path='coa/form' element={<FormCOA />} />
                </Routes>
              } />
              <Route path="/menuinvoice/*" element={
                <Routes>
                  <Route path='invoice' element={<Invoice />} />
                  <Route path='invoice/form' element={<FormInvoice />} />
                  <Route path='invoice/:accountid/:code' element={<DetailInvoice />} />
                </Routes>
              } />
            </Routes>
          </Layouts>
        } />
        <Route path="/customer/*" element={
          <Layouts>
            <Routes>
              <Route path='dashboard' element={<DashboardCustomer />} />
              <Route path='analytic' element={<Analytic />} />
            </Routes>
          </Layouts>
        } />
      </Routes>
    </>
  )
}

export default App