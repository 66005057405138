import { RollbackOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, InputNumber, message, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UploadFile from './UploadFile'
import TableSample from './TableSample'
import dayjs from 'dayjs'
import { postOrder, UploadOrderDocs } from '../../../../API/PostData'
import InputModal from '../../../../Components/InputModal'
import { getCustomers, getOrders } from '../../../../API/GetData'
import HeaderTitle from '../../../../Components/HeaderTitle'

const FormOrder = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [dataSample, setDataSample] = useState([]);
    const [add, setAdd] = useState(false);

    const [orderCode, setOrderCode] = useState("");

    const [dataCustomer, setDataCustomer] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState("");
    const [openCustomer, setOpenCustomer] = useState(null);

    const [filesSupDoc, setFilesSupDoc] = useState(null);
    const [filesAddDoc, setFilesAddDoc] = useState(null);

    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [vat, setVat] = useState(0);

    useEffect(() => {
        const calculateGrandTotal = () => {

            const discountValue = total * (discount / 100);
            const vatValue = (total - discountValue) * (vat / 100);
            const grandTotal = total - discountValue + vatValue;

            if (vat) {
                form.setFieldsValue({ GrandTotal: grandTotal });
            }
        };

        calculateGrandTotal();
    }, [total, discount, vat, form]);

    const handleTotalChange = (value) => setTotal(value);
    const handleDiscountChange = (value) => setDiscount(value);
    const handleVatChange = (value) => setVat(value);

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                setIsLoading(true);
                const res = await getCustomers();
                setDataCustomer(res);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        if (openCustomer) {
            fetchCustomer();
            setOpenCustomer(false);
        }
    }, [openCustomer]);

    useEffect(() => {
        if (form && selectCustomer) {
            form.setFieldsValue({
                CustomerName: selectCustomer.CustomerName,
            })
        }
    }, [form, selectCustomer]);

    useEffect(() => {
        const storageSample = localStorage.getItem('formSampleData');
        if (storageSample) {
            const sample = JSON.parse(storageSample);
            setDataSample(sample);
        }
        const storageOrder = localStorage.getItem('formOrderData');

        if (storageOrder) {
            const sample = JSON.parse(storageOrder);
            const payload = {
                ...sample,
                OrderDate: dayjs(sample.OrderDate),
                ReceivedDate: dayjs(sample.ReceivedDate),
            }
            form.setFieldsValue(payload);
        }
    }, [form]);


    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const res = await getOrders();
                if (res.length > 0) {
                    const filteredData = res.filter(code => code.OrderCode.startsWith("OC"));
                    if (filteredData.length > 0) {
                        const lastCode = filteredData[filteredData.length - 1].OrderCode;
                        const nextNumber = parseInt(lastCode.substr(2)) + 1;
                        const nextCode = `OC${nextNumber.toString().padStart(3, "0")}`
                        setOrderCode(nextCode);
                    } else {
                        setOrderCode("OC001");
                    }
                } else {
                    setOrderCode("OC001");
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchOrders();

    }, []);

    useEffect(() => {
        if (form && orderCode) {
            form.setFieldsValue({ OrderCode: orderCode });
        }
    }, [form, orderCode]);


    const handleSubmit = async (values) => {
        try {
            const storageOrder = JSON.parse(localStorage.getItem('formOrderData'));

            const payload = {
                ...values,
                OrderDate: dayjs(values.OrderDate).format('YYYY-MM-DD'),
                ReceivedDate: dayjs(values.ReceivedDate).format('YYYY-MM-DD'),
                AccountID: storageOrder ? storageOrder.AccountID : selectCustomer.AccountID,
                CustomerId: storageOrder ? storageOrder.CustomerId : selectCustomer.CustomerId,
                NET: values.GrandTotal,
            }
            if (!add) {
                if (!dataSample) {
                    message.error("Complete the Sample!");
                    return
                }
                setIsLoading(true);
                payload.samples = dataSample
                const res = await postOrder(payload);

                if (res.status === 201) {
                    if (filesSupDoc) {
                        try {
                            for (let [index, [key, value]] of [...filesSupDoc.entries()].entries()) {
                                const filePayload = new FormData();

                                // Append static fields to the payload
                                filePayload.append('AccountID', storageOrder ? storageOrder.AccountID : selectCustomer.AccountID);
                                filePayload.append('OrderCode', values.OrderCode);

                                // Append dynamic DocCode based on index
                                filePayload.append('DocCode', `DC${String(index + 1).padStart(3, '0')}`);
                                filePayload.append('DocType', "Support");

                                // Append the current file to the payload
                                filePayload.append(key, value);

                                // Upload the file with the payload
                                const fileResponse = await UploadOrderDocs(filePayload);
                                console.log('File upload response:', fileResponse);
                            }
                        } catch (fileError) {
                            console.error('Error uploading files:', fileError);
                            message.error('Failed to upload support documents');
                        }
                    }
                    if (filesAddDoc) {
                        try {
                            for (let [index, [key, value]] of [...filesAddDoc.entries()].entries()) {
                                const filePayload = new FormData();

                                // Append static fields to the payload
                                filePayload.append('AccountID', storageOrder ? storageOrder.AccountID : selectCustomer.AccountID);
                                filePayload.append('OrderCode', values.OrderCode);

                                // Append dynamic DocCode based on index
                                filePayload.append('DocCode', `AC${String(index + 1).padStart(3, '0')}`);
                                filePayload.append('DocType', "Additional");

                                // Append the current file to the payload
                                filePayload.append(key, value);

                                // Upload the file with the payload
                                const fileResponse = await UploadOrderDocs(filePayload);
                                console.log('File upload response:', fileResponse);
                            }
                        } catch (fileError) {
                            console.error('Error uploading files:', fileError);
                            message.error('Failed to upload support documents');
                        }
                    }
                }

                message.success(res.data.message);
                localStorage.removeItem('formOrderData');
                localStorage.removeItem('formSampleData');
                navigate('/dashboard/menuorder/order');
            } else {
                localStorage.setItem('formOrderData', JSON.stringify(payload));
                navigate('/dashboard/menuorder/order/sample/form');
            }
        } catch (error) {
            console.log(error);
            window.scrollTo(0, 0);
            message.error(error.response.data.message);
        }
        setIsLoading(false);
    };

    const handleReset = () => {
        form.resetFields();
        setTotal(0);
        setDiscount(0);
        setVat(0);
    }

    const handleAdd = async () => {
        try {
            await form.validateFields();
            setAdd(true);
            form.submit();
        } catch (error) {
            message.error("Complete the Order data!");
            window.scrollTo(0, 0);
        }
    };


    return (
        <>
            {/* <TestUpload /> */}
            <div className="flex justify-between items-center">
                <HeaderTitle title="FORM ORDER" subtitle="Create a Order Data" />
                <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)}>Back</Button>
            </div>

            <div className="bg-white p-4 rounded-md mt-4">
                <Form
                    name="formOrder"
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    form={form}
                >

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

                        <Form.Item
                            label="Order Code"
                            name="OrderCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Order Code!',
                                },
                            ]}
                        >
                            <Input placeholder="Order Code" />
                        </Form.Item>

                        <Form.Item
                            label="Order Quotation"
                            name="OrderQuotation"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Order Quotation!',
                                },
                            ]}
                        >
                            <Input placeholder="Order Quotation" />
                        </Form.Item>

                        <Form.Item
                            label="Order Date"
                            name="OrderDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Order Date!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" />
                        </Form.Item>

                        <InputModal
                            title="CUSTOMER"
                            label="Customer"
                            name="CustomerName"
                            dataSource={dataCustomer}
                            loading={isLoading}
                            columns={columnsCustomer}
                            onData={(values) => setSelectCustomer(values)}
                            onOpenModal={(values) => setOpenCustomer(values)}
                        />

                        <Form.Item
                            label="Received Date"
                            name="ReceivedDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Received Date!',
                                },
                            ]}
                        >
                            <DatePicker className="w-full" />
                        </Form.Item>

                        <Form.Item
                            label="Priority"
                            name="Priority"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Priority!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Priority"
                                options={[
                                    { value: 'Normal', label: 'Normal' },
                                    { value: 'Very Urgent', label: 'Very Urgent' },
                                    { value: 'Urgent', label: 'Urgent' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Submitted by"
                            name="SubmittedBy"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Submitted By!',
                                },
                            ]}
                        >
                            <Input placeholder="Submitted by" />
                        </Form.Item>

                        <Form.Item
                            label="Total (Rp)"
                            name="Total"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Total!',
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-full"
                                placeholder="Total"
                                formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/Rp\s?|(,*)/g, '')}
                                onChange={handleTotalChange}
                            />

                        </Form.Item>

                        <Form.Item
                            label="Discount (%)"
                            name="Discount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Discount!',
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                max={100}
                                onChange={handleDiscountChange}
                                placeholder="Discount"
                                suffix="%"
                                className="w-full"
                            />
                        </Form.Item>

                        <Form.Item
                            label="VAT (%)"
                            name="VAT"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your VAT!',
                                },
                            ]}
                        >
                            <InputNumber
                                min={1}
                                max={100}
                                onChange={handleVatChange}
                                placeholder="VAT"
                                suffix="%"
                                className="w-full"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Grand Total (Rp)"
                            name="GrandTotal"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Grand Total!',
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-full"
                                placeholder="Grand Total"
                                formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/Rp\s?|(,*)/g, '')}
                                onChange={handleTotalChange}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Remarks"
                            name="Remarks"
                            // className="md:col-span-2"
                        >
                            <TextArea rows={4} placeholder="Remarks" />
                        </Form.Item>

                    </div>

                    <Form.Item
                        label="Support Document (Optional)"
                        name="SupportDocument"
                    >
                        <UploadFile onFiles={(values) => setFilesSupDoc(values)} />
                    </Form.Item>

                    <Form.Item
                        label="Additional Document (Optional)"
                        name="AdditionalDocument"
                    >
                        <UploadFile onFiles={(values) => setFilesAddDoc(values)} />
                    </Form.Item>

                    <TableSample onData={handleAdd} />

                    <div className="flex flex-row justify-end gap-2">
                        <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                        <Button htmlType="button" onClick={handleReset}>Reset</Button>
                    </div>

                </Form>
            </div>

        </>
    )
}

export default FormOrder;


const columnsCustomer = [
    {
        title: "AccountID",
        dataIndex: "AccountID",
        key: "AccountID",
    },
    {
        title: "CustomerId",
        dataIndex: "CustomerId",
        key: "CustomerId",
    },
    {
        title: "CustomerName",
        dataIndex: "CustomerName",
        key: "CustomerName",
    },
    {
        title: "ContactPerson",
        dataIndex: "ContactPerson",
        key: "ContactPerson",
    },
    {
        title: "Address",
        dataIndex: "Address",
        key: "Address",
    },
    {
        title: "EmailContact",
        dataIndex: "EmailContact",
        key: "EmailContact",
    },
    {
        title: "Phone",
        dataIndex: "Phone",
        key: "Phone",
    },
    {
        title: "MobilePhone",
        dataIndex: "MobilePhone",
        key: "MobilePhone",
    },
    {
        title: "Fax",
        dataIndex: "Fax",
        key: "Fax",
    },
];
