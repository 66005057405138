import React, { useCallback, useState } from 'react'
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { formatRupiah } from '../../../Components/Global';
import { getInvoiceView } from '../../../API/GetData';
import { useEffect } from 'react';
import SearchInput from '../../../Components/SearchInput';

const OutstandingInvoice = ({ onDate1, onDate2 }) => {

    const columns = [
        {
            title: 'Invoice Number',
            dataIndex: 'InvoiceNo',
            key: 'InvoiceNumber',
            sorter: (a, b) => a.InvoiceNumber.localeCompare(b.InvoiceNumber),
            render: (text) => <Link to="" className="text-indigo-600 hover:text-indigo-400 font-medium">{text}</Link>,
        },
        {
            title: 'Invoice Date',
            dataIndex: 'InvoiceDate',
            key: 'InvoiceDate',
            sorter: (a, b) => new Date(a.InvoiceDate) - new Date(b.InvoiceDate),
        },
        {
            title: 'Invoice Sent Date',
            dataIndex: 'InvoiceSentDate',
            key: 'InvoiceSentDate',
            sorter: (a, b) => new Date(a.InvoiceSentDate) - new Date(b.InvoiceSentDate),
        },
        {
            title: 'NET',
            dataIndex: 'NET',
            key: 'NET',
            sorter: (a, b) => a.NET.localeCompare(b.NET),
            render(text) {
                return (
                    <div className="flex justify-between">
                        <p>Rp</p> <p>{formatRupiah(text)}</p>
                    </div>
                )
            }
        },
    ];

    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');


    const fetchData = useCallback(async () => {
        try {
            const res = await getInvoiceView(onDate1, onDate2);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    }, [onDate1, onDate2]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const totalData = data ? data.length : 0;

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = data.filter((item) =>
        Object.values(item).some((val) =>
            val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    // console.log(data);
    
    
    return (
        <div className="flex flex-col gap-4 bg-white p-4 rounded-md">
            <div className="flex justify-between items-center">
                <p className="text-2xl">Outstanding Invoice</p>
                <p>Total Item: {totalData}</p>
            </div>
            <div className="flex justify-end">
                <SearchInput value={searchText} onChange={handleSearch} />
            </div>
            <Table
                columns={columns}
                dataSource={filteredData}
            />
        </div>
    )
}

export default OutstandingInvoice